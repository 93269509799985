import React from 'react';

import { PAGES } from '../../../utils/utils';
import Insurance from '../../../components/en/expertise/insurance';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

const InsurancePage = () => (
  <Layout lang="en" page={PAGES.COMPETENCES_ASSURANCES}>
    <SEO title="Insurance" lang="en" />
    <Insurance />
  </Layout>
);

export default InsurancePage;
