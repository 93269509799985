import React from 'react';

import ExpertiseContainer, { EXPERTISE, generateBreadcrumb } from './container';

const Insurance = () => {
  return (
    <ExpertiseContainer currentOption={EXPERTISE.INSURANCE}>
      <h1>Insurance</h1>

      {generateBreadcrumb('insurance')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="assurances"
          src="/img/competences_sprite.jpg"
          alt="Insurance"
        />
      </div>

      <p>
        We are qualified to resolve disputes related to{' '}
        <span className="accent">insurance claims</span> such as{' '}
        <span className="accent">
          auto insurance, home insurance, disability insurance
        </span>{' '}
        and <span className="accent">life insurance</span>.
      </p>
    </ExpertiseContainer>
  );
};

Insurance.propTypes = {};

export default Insurance;
